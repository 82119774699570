<template>
  <div class="article">
    <div class="aside_management_mode regist_mode">
      <div class="head">
        <h5>제품 등록</h5>
      </div>
      <div class="form">
        <form @submit.prevent>
          <div>
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValid.includes('제품 형태') && baseInfo,
                }"
                >제품 형태</label
              >
              <div>
                <my-selectric
                  v-if="showSelectric"
                  :id="'product_type_selectric'"
                  :watch="newData.resource_type_id"
                  :options="product_options"
                  :commit="'setNewProductTypeId'"
                >
                </my-selectric>
              </div>
            </div>
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValid.includes('제품명') && baseInfo,
                }"
                >제품명</label
              >
              <input
                type="text"
                placeholder=""
                required
                v-model="newData.name"
              />
            </div>
            <div class="input_text">
              <label>규격</label>
              <input type="text" placeholder="" v-model="newData.standard" />
            </div>

            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValid.includes('생산단위') && baseInfo,
                }"
                >생산단위</label
              >
              <div>
                <my-selectric
                  v-if="showSelectric"
                  :id="'unit_selectric'"
                  :watch="newData.stock_unit_id"
                  :options="unit_options"
                  :commit="'setNewStockUnitId'"
                >
                </my-selectric>
              </div>
            </div>
          </div>
          <div>
            <div class="input_checkbox">
              <label for="checkbox2" class="label_txt">그룹제품</label>
              <label
                for="checkbox2"
                class="chk_box"
                :class="newData.product_group_yn ? 'active' : ''"
              >
                <i class="fa fa-check"></i>
              </label>
              <input
                type="checkbox"
                id="checkbox2"
                @click="newData.product_group_yn = !newData.product_group_yn"
              />
            </div>
            <div class="input_text inputs" v-show="newData.product_group_yn">
              <label
                class="require"
                :class="{
                  base_info: checkValid.includes('그룹제품 형태') && baseInfo,
                }"
                >그룹제품 형태</label
              >
              <div>
                <my-selectric
                  v-if="showSelectric"
                  :id="'group_type_selectric'"
                  :watch="newData.product_group_type_id"
                  :options="group_type_options"
                  :commit="'setNewProductGroupTypeId'"
                >
                </my-selectric>
              </div>
            </div>
            <div
              class="input_text"
              v-show="
                !newData.product_group_yn ||
                  (newData.product_group_yn &&
                    newData.product_group_type_id == 2)
              "
            >
              <label>제품식별코드</label>
              <input v-model="newData.code" type="text" placeholder="" />
            </div>
            <div class="input_checkbox" v-if="systemCompany.use_crushed_yn">
              <label for="checkbox102" class="label_txt">분쇄물 분출 </label>
              <label
                for="checkbox102"
                class="chk_box"
                :class="newData.crushed_product_yn ? 'active' : ''"
              >
                <i class="fa fa-check"></i
              ></label>
              <input
                type="checkbox"
                id="checkbox102"
                @click="
                  newData.crushed_product_yn = !newData.crushed_product_yn
                "
              />
            </div>
          </div>

          <div
            v-show="
              !newData.product_group_yn ||
                (newData.product_group_yn && newData.product_group_type_id == 2)
            "
          >
            <div class="input_text inputs">
              <label>출고박스</label>
              <div>
                <my-selectric
                  v-if="showSelectric"
                  :id="'box_selectric'"
                  :watch="newData.box_unit_id"
                  :options="box_options"
                  :commit="'setNewBoxUnitIdToProduct'"
                >
                </my-selectric>
              </div>
            </div>
            <div class="input_text">
              <label>창고</label>
              <my-selectric
                v-if="showSelectric"
                :id="'store_selectric'"
                :watch="newData.default_store_id"
                :options="store_options"
                :commit="'setNewProductDefaultStore'"
              >
              </my-selectric>
            </div>
            <div class="input_text inputs">
              <label>적정 재고량</label>
              <input
                type="text"
                placeholder="3"
                :value="$makeComma(newData.stock_size)"
                inputmode="decimal"
                @input="$inputNumberInt($event, newData, 'stock_size')"
              />
              <label>{{
                newData.stock_unit_id == null
                  ? ''
                  : getUnitName(newData.stock_unit_id)
              }}</label>
            </div>
          </div>

          <div
            v-show="
              !newData.product_group_yn ||
                (newData.product_group_yn && newData.product_group_type_id == 2)
            "
          >
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValid.includes('완제품검사') && baseInfo,
                }"
                >완제품검사</label
              >
              <my-selectric
                v-if="showSelectric"
                :id="'inspection_selectric'"
                :watch="newData.inspection"
                :options="inspection_options"
                :commit="'setNewProductInspection'"
              >
              </my-selectric>
            </div>
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValid.includes('과세 여부') && baseInfo,
                }"
                >과세 여부</label
              >
              <my-selectric
                v-if="showSelectric"
                :id="'tax_selectric'"
                :watch="newData.tax"
                :options="tax_options"
                :commit="'setNewProductTax'"
              >
              </my-selectric>
            </div>
          </div>
          <div>
            <div class="input_checkbox" v-if="systemCompany.oem_product_yn">
              <label for="checkbox529" class="label_txt">OEM제품</label>
              <label
                for="checkbox529"
                class="chk_box"
                :class="newData.oem_product_yn ? 'active' : ''"
              >
                <i class="fa fa-check"></i>
              </label>
              <input
                type="checkbox"
                id="checkbox529"
                @click="newData.oem_product_yn = !newData.oem_product_yn"
              />
              <p class="warning" v-if="newData.oem_product_yn">
                OEM제품에 체크한 경우 제품 생산현황에 이 제품이 노출되지
                않습니다.
              </p>
            </div>
          </div>
          <div
            v-if="
              systemCompany.expire_yn &&
                (!newData.product_group_yn ||
                  (newData.product_group_yn &&
                    newData.product_group_type_id == 2))
            "
          >
            <div class="input_text inputs expiration_date">
              <label>유통기한</label>
              <label>제조일로부터</label>
              <input
                type="text"
                placeholder="3"
                :value="$makeComma(newData.expire_year)"
                inputmode="decimal"
                @input="$inputNumberInt($event, newData, 'expire_year', 100, 0)"
              />
              <label>년</label>
              <input
                type="text"
                placeholder="12"
                :value="$makeComma(newData.expire_month)"
                inputmode="decimal"
                @input="$inputNumberInt($event, newData, 'expire_month', 12, 0)"
              />
              <label>개월</label>
              <input
                type="text"
                placeholder="30"
                :value="$makeComma(newData.expire_day)"
                inputmode="decimal"
                @input="$inputNumberInt($event, newData, 'expire_day', 365, 0)"
              />
              <label>일</label>
            </div>
          </div>
          <div>
            <div
              class="input_text inputs add"
              v-show="
                !newData.product_group_yn ||
                  (newData.product_group_yn &&
                    newData.product_group_type_id == 2)
              "
            >
              <label>매출처</label>
              <div>
                <draggable
                  class="selected_items_wrap"
                  :list="newData.company_list"
                  delay-on-touch-only="true"
                  delay="80"
                >
                  <div
                    class="selected_item"
                    :class="index == 0 ? 'default' : ''"
                    v-for="(company, index) in newData.company_list"
                    :key="company.company_id"
                  >
                    <span>{{ getCompanyName(company.company_id) }}</span
                    ><button
                      type="button"
                      class="delete_item"
                      @click.prevent.stop="deleteNewCompany(index)"
                    ></button>
                  </div>
                </draggable>
                <div>
                  <button
                    type="button"
                    class="btn_ledger"
                    @click="showCompanySearch = true"
                  >
                    매출처 선택
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              class="input_text inputs add"
              v-show="
                newData.product_group_yn &&
                  newData.product_group_type_id != null
              "
            >
              <label>포장 제품</label>
              <div
                class="mes_tbl_wrap"
                v-show="
                  newData.product_group_yn &&
                    newData.product_group_type_id != null
                "
              >
                <table class="mes_tbl">
                  <thead>
                    <tr>
                      <th>포장제품</th>
                      <th>규격</th>
                      <th>단위</th>
                      <th>내용량</th>
                      <th>삭제</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in newData.product_group_list"
                      :key="item.id"
                    >
                      <td class="text_left">
                        {{
                          findInfoFromId(products, item.member_product_id).name
                        }}
                      </td>
                      <td class="text_left">
                        {{
                          findInfoFromId(products, item.member_product_id)
                            .standard
                        }}
                      </td>
                      <td>
                        {{ newData.product_group_type_id == 1 ? 1 : '' }}
                        {{
                          findInfoFromId(
                            units,
                            findInfoFromId(products, item.member_product_id)
                              .stock_unit_id,
                          ).name
                        }}
                      </td>
                      <td>
                        <input
                          type="text"
                          :value="$makeComma(item.quantity)"
                          inputmode="decimal"
                          @input="$inputNumber($event, item, 'quantity')"
                        />
                        {{
                          newData.product_group_type_id == 1
                            ? findInfoFromId(units, newData.stock_unit_id).name
                            : ''
                        }}
                      </td>
                      <td>
                        <button
                          class="tbl_delete_btn"
                          @click="newData.product_group_list.splice(index, 1)"
                        >
                          delete
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" class="new">
                        <button
                          class="btn_tbl"
                          @click="showProductSearch = true"
                        >
                          제품 선택
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="remark">
            <div class="input_text">
              <label>비고</label>
              <textarea v-model="newData.detail"> </textarea>
            </div>
          </div>
          <div class="btn_wrap">
            <button type="button" class="btn_sub2" @click="submitForm()">
              저장
            </button>
          </div>
        </form>
      </div>
    </div>
    <company-search
      v-if="showCompanySearch"
      :search_type="1"
      @onclose="showCompanySearch = false"
      @onselect="pushNewCompany($event)"
    ></company-search>
    <resource-search
      v-if="showProductSearch"
      :filter_type="2"
      :ignore_list="ignore_list"
      :pre_search_value="''"
      @onclose="showProductSearch = false"
      @onselect="
        $event => {
          newData.product_group_list.push({
            id: $event.id,
            resource_type_id: $event.resource_type_id,
            group_product_id: null,
            member_product_id: $event.id,
            quantity: 0,
          });
          ignore_list = newData.product_group_list;
          showProductSearch = false;
        }
      "
    ></resource-search>
  </div>
</template>

<script>
import MySelectric from '@/layouts/components/MySelectric';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';
import draggable from 'vuedraggable';
import fetchMixin from '@/mixins/fetch';
import { mapGetters } from 'vuex';
export default {
  mixins: [ModalMixin, SpinnerMixin, fetchMixin],
  data() {
    return {
      showCompanySearch: false,
      showProductSearch: false,
      ignore_list: [],
      showSelectric: false,
      inspection_options: [
        { label: '선택', value: null },
        { label: '생략', value: false },
        { label: '필요', value: true },
      ],
      tax_options: [
        { label: '선택', value: null },
        { label: '과세', value: true },
        { label: '면세', value: false },
      ],
      baseInfo: false,
    };
  },
  components: {
    MySelectric,
    CompanySearch,
    ResourceSearch,
    draggable,
  },
  computed: {
    ...mapGetters({
      product_types: 'getProductType',
      product_options: 'getProductTypeForSelectricSelect',
      units: 'getUnitCodes',
      box_options: 'getBoxForSelectric',
      unit_options: 'getUnitForSelectric',
      group_type_options: 'getGroupTypeForSelectric',
      newData: 'getNewDataFromProduct',
      store_options: 'getStoresForSelectric',
      stores: 'getStores',
      companys: 'getCompany',
      products: 'getProduct',
      systemCompany: 'getSystemCompany',
    }),
    checkValid() {
      let chk = [];

      if (this.newData.name == undefined || this.newData.name.trim() === '') {
        chk.push('제품명');
      }

      if (
        this.newData.resource_type_id == undefined ||
        this.newData.resource_type_id == null
      ) {
        chk.push('제품 형태');
      }

      if (
        this.newData.stock_unit_id == undefined ||
        this.newData.stock_unit_id == null
      ) {
        chk.push('생산단위');
      }

      if (
        (!this.newData.product_group_yn ||
          (this.newData.product_group_yn &&
            this.newData.product_group_type_id == 2)) &&
        (this.newData.inspection == undefined ||
          this.newData.inspection == null)
      ) {
        chk.push('완제품검사');
      }

      if (
        (!this.newData.product_group_yn ||
          (this.newData.product_group_yn &&
            this.newData.product_group_type_id == 2)) &&
        (this.newData.tax == undefined || this.newData.tax == null)
      ) {
        chk.push('과세 여부');
      }

      if (
        this.newData.product_group_yn &&
        (this.newData.product_group_type_id == undefined ||
          this.newData.product_group_type_id == null)
      ) {
        chk.push('그룹제품 형태');
      }
      return chk;
    },
    validName() {
      // 제품이름 중복검사
      const productListChk = this.lodash
        .clonedeep(this.products)
        .find(
          x =>
            x.name == this.newData.name.trim() &&
            x.standard == this.newData.standard.trim(),
        );

      if (productListChk != undefined) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getUnitName(id) {
      let hitValue = this.unit_options.find(x => x.value == id);
      return hitValue == undefined ? '' : hitValue.label;
    },
    // toggleTax() {
    //   this.newData.tax = !this.newData.tax;
    // },
    pushNewCompany(arg) {
      console.log(arg);
      if (
        this.newData.company_list.find(x => x.company_id == arg.id) != undefined
      ) {
        this.showCompanySearch = false;
        return;
      }
      this.newData.company_list.push({
        company_id: arg.id,
        product_id: null,
      });

      this.showCompanySearch = false;
    },
    getCompanyName(id) {
      let hitValue = this.companys.find(x => x.id == id);
      return hitValue == undefined ? '' : hitValue.name;
    },
    deleteNewCompany(index) {
      this.newData.company_list.splice(index, 1);
    },

    async FETCHS(str) {
      this.showSpinner();
      await this.$store
        .dispatch(str)
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '자재 관련 정보 로드 중 오류',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },

    async submitForm() {
      if (this.checkValid.length == 0 && !this.validName) {
        this.showSpinner();
        let payload = this.lodash.clonedeep(this.newData);
        if (payload.product_group_yn && payload.product_group_type_id == 1) {
          payload.inspection = false;
          payload.tax = false;
        }

        payload.name = payload.name.trim();
        payload.standard = payload.standard.trim();
        payload.code = payload.code.trim();
        payload.delivery_unit_id = payload.stock_unit_id;
        payload.stock_size = this.$makeNumber(payload.stock_size);
        await this.$store
          .dispatch('INSERT_PRODUCT', payload)
          .then(async response => {
            console.log(payload.company_list);
            if (payload.company_list.length > 0) {
              await this.INSERT_PRODUCT_COMPANY(response.data.data.id);
            }
            if (payload.product_group_list.length > 0) {
              await payload.product_group_list.forEach(element => {
                element.group_product_id = response.data.data.id;
                element.quantity = this.$makeNumber(element.quantity);
              });
              await this.INSERT_WITH_PAYLOAD(
                'INSERT_PRODUCT_GROUP_LIST',
                {
                  data_list: payload.product_group_list,
                },
                '제품그룹',
              );
            }

            this.openOneButtonModal('등록 성공', '성공적으로 등록하였습니다.');
            this.baseInfo = false;
            await this.$store.commit('setNewDataToProduct', {
              name: '',
              product_group_yn: false,
              product_group_list: [],
              product_group_type_id: null,
              standard: '',
              code: '',
              stock_size: 0,
              stock_unit_id: null,
              delivery_unit_id: -1,
              resource_type_id: null,
              box_unit_id: null,
              default_store_id: null,
              detail: '',
              company_list: [],
              tax: null,
              inspection: null,
              expire_year: 0,
              expire_month: 0,
              expire_day: 0,
              oem_product_yn: false,
              crushed_product_yn: false,
            });
            this.$store.dispatch('FETCH_PRODUCT_WITH_COMPANY');
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal('저장 중 에러', '제품 저장 중 에러발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else if (this.checkValid.length > 0) {
        this.openOneButtonModal(
          '등록 불가',
          `${this.checkValid.join()}은(는) 반드시 입력하여 주십시오.`,
        );
        this.baseInfo = true;
      } else if (this.validName) {
        this.openOneButtonModal(
          '등록 불가',
          `같은 이름의 제품이 등록이 되어있습니다.<br />다른 제품명을 등록해주세요.`,
        );
      }
    },
    async INSERT_PRODUCT_COMPANY(mat_id) {
      if (this.newData.company_list.length > 0) {
        let dl = [];
        this.newData.company_list.forEach(el => {
          dl.push({
            product_id: mat_id,
            company_id: el.company_id,
          });
        });

        await this.$store
          .dispatch('INSERT_SELLER_COMPANY_LIST', { data_list: dl })
          .then(() => {})
          .catch(error => {
            console.log(error);
            this.openOneButtonModal('저장 중 에러', '제품 저장 중 에러발생');
          })
          .finally(() => {});
      }
    },
  },

  async created() {
    if (this.product_types.length == 0) {
      await this.FETCHS('FETCH_RESOURCE_TYPE');
    }
    if (this.units.length == 0) {
      await this.FETCHS('FETCH_UNIT');
    }
    if (this.box_options.length <= 1) {
      await this.FETCHS('FETCH_BOX');
    }
    if (this.stores.length == 0) {
      await this.FETCHS('FETCH_STORE');
    }
    if (this.companys.length == 0) {
      await this.FETCHS('FETCH_COMPANY');
    }

    if (this.newData.resource_type_id == -1) {
      await this.$store.commit('setNewProductTypeId', this.product_types[0].id);
    }
    if (this.newData.stock_unit_id == -1) {
      await this.$store.commit('setNewStockUnitId', this.units[0].id);
    }
    this.showSelectric = true;
  },
};
</script>

<style scoped></style>
