<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <span class="option_txt">제품 현황</span>
        <div>
          <span class="option_txt">제품 형태</span>
          <div>
            <my-local-selectric
              v-if="showSelectric"
              :id="'search_type_selectric'"
              :watch="search_type"
              :options="
                product_options_all.concat({ label: '삭제기록', value: 9 })
              "
              @changeValue="changeSelectric($event)"
            >
            </my-local-selectric>
          </div>
        </div>
        <div>
          <span class="option_txt">제품명</span>
          <div class="input_search">
            <input
              type="text"
              placeholder="제품명 또는 규격 검색"
              :value="search_value"
              @input="typing"
            />
            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
        <div>
          <span class="option_txt">비고</span>
          <div class="input_search">
            <input
              type="text"
              placeholder="비고내용 검색"
              :value="detail_value"
              @input="typingDetail"
            />
            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <h6>조회수 : {{ filtered_products.length }}건</h6>
      <div class="input_checkbox">
        <label for="checkbox2" class="label_txt">그룹 제품만 보기</label>
        <label
          for="checkbox2"
          class="chk_box"
          :class="check_only_group_product ? 'active' : ''"
        >
          <i class="fa fa-check"></i>
        </label>
        <input
          type="checkbox"
          id="checkbox2"
          @click="
            selectRow(-1);
            $store.commit(
              'setCheckOnlyGroupProductToProduct',
              !check_only_group_product,
            );
          "
        />
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl" :class="{ manage: managementMode }">
          <colgroup>
            <col v-for="(n, index) in managementMode ? 11 : 10" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>제품 형태</th>
              <th>제품명</th>
              <th>규격</th>
              <th>그룹제품</th>
              <th>제품식별코드</th>
              <th>생산단위</th>
              <th>매출처</th>
              <th>완제품검사</th>
              <th>과세 여부</th>
              <th>BOM</th>
              <th v-show="managementMode">
                {{ search_type == 9 ? '복구' : '삭제' }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(product, index) in filtered_products"
              :key="product.id"
              @click="selectRow(index)"
              :class="selectedIndex == index ? 'active' : ''"
            >
              <td>{{ getResourceTypeName(product.resource_type_id) }}</td>

              <td class="text_left">{{ product.name }}</td>
              <td class="text_left">{{ product.standard }}</td>
              <td>{{ product.product_group_yn ? 'O' : 'X' }}</td>
              <td>{{ product.code }}</td>
              <td>
                {{ findInfoFromId(units, product.stock_unit_id).name }}
              </td>
              <td class="text_left">
                {{
                  product.product_group_yn
                    ? getProductGroupName(product.product_group_list)
                    : getPrimaryCompanyName(
                        product.company_list.length > 0
                          ? product.company_list
                          : '',
                      )
                }}
              </td>
              <td>
                {{
                  inspection_options.find(x => x.value == product.inspection)
                    .label
                }}
              </td>
              <td>{{ tax_options.find(x => x.value == product.tax).label }}</td>
              <td>
                <button
                  class="btn_tbl"
                  @click="
                    boms.find(x => x.product_id == product.id) != undefined
                      ? checkProcess(product.id)
                      : newProduct(product.id)
                  "
                >
                  {{
                    boms.find(x => x.product_id == product.id) != undefined
                      ? '조회'
                      : '등록'
                  }}
                </button>
              </td>
              <td v-show="managementMode && search_type != 9">
                <button class="tbl_delete_btn" @click="ShowModal(index)">
                  delete
                </button>
              </td>
              <td
                v-show="managementMode && search_type == 9"
                class="tbl_restore"
              >
                <button class="btn_tbl" @click="restoreProduct(product)">
                  {{ `◀` }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode">
      <div class="head">
        <h5>세부정보</h5>
        <button
          class="btn_sub1"
          v-show="selectedIndex != -1"
          v-if="isPermissionOn"
          @click="copyData()"
        >
          복사 후 신규등록
        </button>
      </div>
      <div class="form">
        <form @submit.prevent>
          <div>
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValidate.includes('제품 형태') && baseInfo,
                }"
                >제품 형태</label
              >
              <div>
                <my-selectric
                  v-if="showSelectric"
                  :id="'product_type_selectric'"
                  :watch="managementData.resource_type_id"
                  :options="product_options"
                  :commit="'setManagementProductTypeId'"
                  :class="
                    managementMode && selectedIndex != -1 ? '' : 'disabled'
                  "
                >
                </my-selectric>
              </div>
            </div>
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValidate.includes('제품명') && baseInfo,
                }"
                >제품명</label
              >
              <input
                type="text"
                placeholder=""
                required
                v-model="managementData.name"
                :disabled="!managementMode || selectedIndex == -1"
              />
            </div>
          </div>
          <div>
            <div class="input_text">
              <label>규격</label>
              <input
                type="text"
                placeholder="1Box / 20Kg 5EA"
                :disabled="!managementMode || selectedIndex == -1"
                v-model="managementData.standard"
              />
            </div>
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValidate.includes('생산단위') && baseInfo,
                }"
                >생산단위</label
              >
              <div>
                <my-selectric
                  v-if="showSelectric"
                  :id="'unit_selectric'"
                  :watch="managementData.stock_unit_id"
                  :options="unit_options"
                  :commit="'setManagementStockUnitId'"
                  :class="
                    managementMode && selectedIndex != -1 ? '' : 'disabled'
                  "
                >
                </my-selectric>
              </div>
            </div>
          </div>
          <div>
            <div class="input_checkbox">
              <label for="checkbox3" class="label_txt">그룹제품</label>
              <label
                for="checkbox3"
                class="chk_box"
                :class="managementData.product_group_yn ? 'active' : ''"
              >
                <i class="fa fa-check"></i>
              </label>
              <input
                type="checkbox"
                id="checkbox3"
                @click="
                  managementData.product_group_yn = !managementData.product_group_yn
                "
                :disabled="!managementMode || selectedIndex == -1"
              />
            </div>
            <div class="input_text" v-show="managementData.product_group_yn">
              <label
                class="require"
                :class="{
                  base_info: checkValidate.includes('그룹제품형태') && baseInfo,
                }"
                >그룹제품형태</label
              >
              <div>
                <my-selectric
                  v-if="showSelectric"
                  :id="'group_type_selectric'"
                  :watch="managementData.product_group_type_id"
                  :options="group_type_options"
                  :commit="'setManagementProductGroupTypeId'"
                  :class="
                    managementMode && selectedIndex != -1 ? '' : 'disabled'
                  "
                >
                </my-selectric>
              </div>
            </div>
          </div>

          <div
            v-show="
              !managementData.product_group_yn ||
                (managementData.product_group_yn &&
                  managementData.product_group_type_id == 2)
            "
          >
            <div class="input_text inputs">
              <label>출고박스</label>
              <div>
                <my-selectric
                  v-if="showSelectric"
                  :id="'box_selectric'"
                  :watch="managementData.box_unit_id"
                  :options="box_options"
                  :commit="'setManagementBoxUnitIdToProduct'"
                  :class="
                    managementMode && selectedIndex != -1 ? '' : 'disabled'
                  "
                >
                </my-selectric>
              </div>
            </div>
            <div class="input_text">
              <label>창고</label>
              <my-selectric
                v-if="showSelectric"
                :id="'store_selectric'"
                :watch="managementData.default_store_id"
                :options="store_options"
                :commit="'setManagementProductDefaultStore'"
                :class="managementMode && selectedIndex != -1 ? '' : 'disabled'"
              >
              </my-selectric>
            </div>
          </div>
          <div
            v-show="
              !managementData.product_group_yn ||
                (managementData.product_group_yn &&
                  managementData.product_group_type_id == 2)
            "
          >
            <div class="input_text inputs">
              <label>적정 재고량</label>
              <input
                type="text"
                placeholder="3"
                :value="$makeComma(managementData.stock_size)"
                inputmode="decimal"
                @input="$inputNumberInt($event, managementData, 'stock_size')"
                :disabled="!managementMode || selectedIndex == -1"
              />
              <label class="unit_txt">{{
                managementData.stock_unit_id == null
                  ? ''
                  : getUnitName(managementData.stock_unit_id)
              }}</label>
            </div>
          </div>
          <div>
            <div class="input_checkbox" v-if="systemCompany.use_crushed_yn">
              <label for="checkbox529" class="label_txt">분쇄물 분출</label>
              <label
                for="checkbox529"
                class="chk_box"
                :class="managementData.crushed_product_yn ? 'active' : ''"
              >
                <i class="fa fa-check"></i>
              </label>
              <input
                type="checkbox"
                id="checkbox529"
                @click="
                  managementData.crushed_product_yn = !managementData.crushed_product_yn
                "
                :disabled="!managementMode || selectedIndex == -1"
              />
            </div>
            <div
              class="input_text"
              v-show="
                !managementData.product_group_yn ||
                  (managementData.product_group_yn &&
                    managementData.product_group_type_id == 2)
              "
            >
              <label>제품식별코드</label>
              <input
                v-model="managementData.code"
                type="text"
                placeholder="TKG-00"
                :disabled="!managementMode || selectedIndex == -1"
              />
            </div>
          </div>
          <div
            v-show="
              !managementData.product_group_yn ||
                (managementData.product_group_yn &&
                  managementData.product_group_type_id == 2)
            "
          >
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValidate.includes('완제품검사') && baseInfo,
                }"
                >완제품검사</label
              >
              <my-selectric
                v-if="showSelectric"
                :id="'inspection_selectric'"
                :watch="managementData.inspection"
                :options="inspection_options"
                :commit="'setManagementProductInspection'"
                :class="managementMode && selectedIndex != -1 ? '' : 'disabled'"
              >
              </my-selectric>
            </div>
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: checkValidate.includes('과세 여부') && baseInfo,
                }"
                >과세 여부</label
              >
              <my-selectric
                v-if="showSelectric"
                :id="'tax_selectric'"
                :watch="managementData.tax"
                :options="tax_options"
                :commit="'setManagementProductTax'"
                :class="managementMode && selectedIndex != -1 ? '' : 'disabled'"
              >
              </my-selectric>
            </div>
          </div>
          <div v-if="systemCompany.oem_product_yn">
            <div class="input_checkbox">
              <label for="checkbox6340" class="label_txt">OEM제품</label>
              <label
                for="checkbox6340"
                class="chk_box"
                :class="managementData.oem_product_yn ? 'active' : ''"
              >
                <i class="fa fa-check"></i>
              </label>
              <input
                type="checkbox"
                id="checkbox6340"
                @click="
                  managementData.oem_product_yn = !managementData.oem_product_yn
                "
                :disabled="!managementMode || selectedIndex == -1"
              />
              <p class="warning" v-if="managementData.oem_product_yn">
                OEM제품에 체크한 경우 제품 생산현황에 이 제품이 노출되지
                않습니다.
              </p>
            </div>
          </div>
          <div
            v-if="
              systemCompany.expire_yn &&
                (!managementData.product_group_yn ||
                  (managementData.product_group_yn &&
                    managementData.product_group_type_id == 2))
            "
          >
            <div class="input_text inputs expiration_date">
              <label>유통기한</label>
              <label>제조일로부터</label>
              <input
                type="text"
                placeholder="3"
                :value="$makeComma(managementData.expire_year)"
                inputmode="decimal"
                :disabled="!managementMode || selectedIndex == -1"
                @input="
                  $inputNumberInt($event, managementData, 'expire_year', 100, 0)
                "
              />
              <label>년</label>
              <input
                type="text"
                placeholder="12"
                :value="$makeComma(managementData.expire_month)"
                inputmode="decimal"
                :disabled="!managementMode || selectedIndex == -1"
                @input="
                  $inputNumberInt($event, managementData, 'expire_month', 12, 0)
                "
              />
              <label>개월</label>
              <input
                type="text"
                placeholder="30"
                :value="$makeComma(managementData.expire_day)"
                inputmode="decimal"
                :disabled="!managementMode || selectedIndex == -1"
                @input="
                  $inputNumberInt($event, managementData, 'expire_day', 365, 0)
                "
              />
              <label>일</label>
            </div>
          </div>
          <div
            v-show="
              !managementData.product_group_yn ||
                (managementData.product_group_yn &&
                  managementData.product_group_type_id == 2)
            "
          >
            <div class="input_text inputs add">
              <label>매출처</label>
              <div>
                <draggable
                  class="selected_items_wrap"
                  :list="managementData.company_list"
                  delay-on-touch-only="true"
                  delay="80"
                  :disabled="!managementMode"
                >
                  <div
                    class="selected_item"
                    :class="index == 0 ? 'default' : ''"
                    v-for="(managementCompany,
                    index) in managementData.company_list"
                    :key="managementCompany.id"
                  >
                    <span>{{
                      getCompanyName(managementCompany.company_id)
                    }}</span
                    ><button
                      type="button"
                      class="delete_item"
                      @click.prevent.stop="deleteManagementCompany(index)"
                      v-show="managementMode && selectedIndex != -1"
                    ></button>
                  </div>
                </draggable>
                <div>
                  <button
                    type="button"
                    class="btn_ledger"
                    @click="showCompanySearch = true"
                    v-show="managementMode && selectedIndex != -1"
                  >
                    매출처 선택
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="
              managementData.product_group_yn &&
                managementData.product_group_type_id != null
            "
          >
            <div class="input_text inputs add">
              <label>포장 제품</label>
              <div class="mes_tbl_wrap">
                <table class="mes_tbl">
                  <thead>
                    <tr>
                      <th>포장제품</th>
                      <th>규격</th>
                      <th>단위</th>
                      <th>내용량</th>
                      <th v-show="managementMode">삭제</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in managementData.product_group_list"
                      :key="item.id"
                    >
                      <td class="text_left">
                        {{
                          findInfoFromId(products, item.member_product_id).name
                        }}
                      </td>
                      <td class="text_left">
                        {{
                          findInfoFromId(products, item.member_product_id)
                            .standard
                        }}
                      </td>
                      <td>
                        {{ managementData.product_group_type_id == 1 ? 1 : '' }}
                        {{
                          findInfoFromId(
                            units,
                            findInfoFromId(products, item.member_product_id)
                              .stock_unit_id,
                          ).name
                        }}
                      </td>
                      <td>
                        <input
                          type="text"
                          :readonly="!managementMode"
                          :value="$makeComma(item.quantity)"
                          inputmode="decimal"
                          @input="
                            $inputNumber($event, item, 'quantity');
                            touchflag = true;
                          "
                        />
                        <label>
                          {{
                            managementData.product_group_type_id == 1
                              ? findInfoFromId(
                                  units,
                                  managementData.stock_unit_id,
                                ).name
                              : ''
                          }}
                        </label>
                      </td>
                      <td v-show="managementMode">
                        <button
                          class="tbl_delete_btn"
                          @click="
                            managementData.product_group_list.splice(index, 1)
                          "
                        >
                          delete
                        </button>
                      </td>
                    </tr>
                    <tr v-show="managementMode">
                      <td colspan="5" class="new">
                        <button
                          class="btn_tbl"
                          @click="showProductSearch = true"
                        >
                          제품 선택
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="remark">
            <div class="input_text">
              <label>비고</label>
              <textarea
                v-model="managementData.detail"
                :disabled="!managementMode || selectedIndex == -1"
              >
              </textarea>
            </div>
          </div>
          <div class="btn_wrap">
            <button
              type="button"
              class="btn_sub2"
              @click="submitForm()"
              v-show="managementMode && selectedIndex != -1"
              :disabled="isValidModify"
            >
              저장
              <!-- :disabled="isValidModify || !checkValid" -->
            </button>
          </div>
        </form>
      </div>
    </div>
    <company-search
      v-if="showCompanySearch"
      :search_type="1"
      @onclose="showCompanySearch = false"
      @onselect="pushManagementCompany($event)"
    ></company-search>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="deleteProduct()"
    ></two-button-modal>
    <resource-search
      v-if="showProductSearch"
      :filter_type="2"
      :ignore_list="makeIgnoreList"
      :pre_search_value="''"
      @onclose="showProductSearch = false"
      @onselect="
        $event => {
          managementData.product_group_list.push({
            id: $event.id,
            resource_type_id: $event.resource_type_id,
            group_product_id: managementData.id,
            member_product_id: $event.id,
            quantity: 0,
          });
          ignore_list = managementData.product_group_list;
          showProductSearch = false;
        }
      "
    ></resource-search>
  </div>
</template>

<script>
import { clone } from '@/utils/func';
import MySelectric from '@/layouts/components/MySelectric';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import ModalMixin from '@/mixins/modal';
import FavoriteMixin from '@/mixins/favorite';
import SpinnerMixin from '@/mixins/spinner';
import FetchMixin from '@/mixins/fetch';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
import routes from '@/routes/routes';

export default {
  mixins: [ModalMixin, SpinnerMixin, FavoriteMixin, FetchMixin],
  data() {
    return {
      search_value: '',
      detail_value: '',
      showCompanySearch: false,
      showSelectric: false,
      inspection_options: [
        { label: '선택', value: null },
        { label: '생략', value: false },
        { label: '필요', value: true },
      ],
      showProductSearch: false,
      ignore_list: [],

      //delete modal
      baseInfo: false,
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,

      tax_options: [
        { label: '선택', value: null },
        { label: '과세', value: true },
        { label: '면세', value: false },
      ],
      touchflag: false,
    };
  },
  components: {
    MySelectric,
    CompanySearch,
    TwoButtonModal,
    draggable,
    ResourceSearch,
    MyLocalSelectric,
  },
  computed: {
    ...mapGetters({
      products: 'getVisibleProduct',
      del_products: 'getDeletedProduct',
      product_types: 'getProductType',
      product_options: 'getProductTypeForSelectricSelect',
      product_options_all: 'getProductTypeForSelectricAll',
      units: 'getUnitCodes',
      box_options: 'getBoxForSelectric',
      unit_options: 'getUnitForSelectric',
      group_type_options: 'getGroupTypeForSelectric',
      search_type: 'getSearchTypeFromProductPage',
      managementMode: 'getManagementModeFromProductPage',
      managementData: 'getManagementDataFromProduct',
      store_options: 'getStoresForSelectric',
      stores: 'getStores',
      selectedIndex: 'getSelectedIndexFromProduct',
      companys: 'getCompany',
      boms: 'getBom',
      check_only_group_product: 'getCheckOnlyGroupProductFromProduct',
      systemCompany: 'getSystemCompany',
    }),

    filtered_products() {
      const Hangul = require('hangul-js');
      return this.lodash
        .clonedeep(this.search_type == 9 ? this.del_products : this.products)
        .filter(
          x =>
            x.resource_type_id == this.search_type ||
            this.search_type == null ||
            this.search_type == 9,
        )
        .filter(
          x =>
            x.name.includes(this.search_value) ||
            Hangul.d(x.name, true)
              .map(x => x[0])
              .join('')
              .includes(this.search_value) ||
            x.standard.includes(this.search_value) ||
            Hangul.d(x.standard, true)
              .map(x => x[0])
              .join('')
              .includes(this.search_value),
        )
        .filter(
          x =>
            x.detail.includes(this.detail_value) ||
            Hangul.d(x.detail, true)
              .map(x => x[0])
              .join('')
              .includes(this.detail_value),
        )
        .filter(x => !this.check_only_group_product || x.product_group_yn)
        .sort((a, b) => {
          return a.name < b.name ? -1 : a.name == b.name ? 0 : 1;
        });
    },
    // checkValid() {
    //   if (
    //     this.managementData == undefined ||
    //     this.managementData == null ||
    //     this.managementData.name == undefined ||
    //     this.managementData.name.trim() === '' ||
    //     this.managementData.resource_type_id == undefined ||
    //     this.managementData.resource_type_id == null ||
    //     this.managementData.stock_unit_id == undefined ||
    //     this.managementData.stock_unit_id == null ||
    //     (!this.managementData.product_group_yn &&
    //       (this.managementData.inspection == undefined ||
    //         this.managementData.inspection == null ||
    //         this.managementData.tax == undefined ||
    //         this.managementData.tax == null))
    //   ) {
    //     return false;
    //   } else return true;
    // },
    checkValidate() {
      let chk = [];

      if (
        this.managementData.name == undefined ||
        this.managementData.name.trim() == ''
      ) {
        chk.push('제품명');
      }

      if (
        this.managementData.resource_type_id == null ||
        this.managementData.resource_type_id == undefined
      ) {
        chk.push('제품 형태');
      }

      if (
        this.managementData.stock_unit_id == null ||
        this.managementData.stock_unit_id == undefined
      ) {
        chk.push('생산단위');
      }
      if (
        ((!this.managementData.product_group_yn ||
          (this.managementData.product_group_yn &&
            this.managementData.product_group_type_id == 2)) &&
          this.managementData.inspection == null) ||
        this.managementData.inspection == undefined
      ) {
        chk.push('완제품검사');
      }

      if (
        ((!this.managementData.product_group_yn ||
          (this.managementData.product_group_yn &&
            this.managementData.product_group_type_id == 2)) &&
          this.managementData.tax == undefined) ||
        this.managementData.tax == null
      ) {
        chk.push('과세여부');
      }

      if (
        this.managementData.product_group_yn &&
        (this.managementData.product_group_type_id == undefined ||
          this.managementData.product_group_type_id == null)
      ) {
        chk.push('그룹제품형태');
      }

      return chk;
    },
    isValidModify() {
      if (this.managementMode && this.selectedIndex != -1) {
        if (this.touchflag) {
          return false;
        } else {
          let modifyData = this.lodash.clonedeep(this.managementData);
          modifyData.name = modifyData.name.trim();
          modifyData.standard = modifyData.standard.trim();
          modifyData.code = modifyData.code.trim();
          modifyData.stock_size = this.$makeNumber(modifyData.stock_size);
          modifyData = JSON.stringify(modifyData);

          let originData = this.lodash.clonedeep(
            this.filtered_products[this.selectedIndex],
          );
          if (this.managementData.product_group_yn) {
            originData.product_group_list.forEach(el => {
              el.id = el.member_product_id;
            });
          }

          originData = JSON.stringify(originData);

          if (modifyData == originData) {
            return true;
          } else return false;
        }
      } else return true;
    },
    makeIgnoreList() {
      const ignoreTemp = this.lodash.clonedeep(
        this.managementData.product_group_list,
      );
      ignoreTemp.forEach(el => {
        el.id = el.member_product_id;
        el.resource_type_id = this.findInfoFromId(
          this.products,
          el.member_product_id,
        ).resource_type_id;
      });
      return ignoreTemp;
    },
    validName() {
      const productListChk = this.lodash
        .clonedeep(this.products)
        .find(
          x =>
            x.id != this.managementData.id &&
            x.name == this.managementData.name.trim() &&
            x.standard == this.managementData.standard.trim(),
        );

      if (productListChk != undefined) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async restoreProduct(product) {
      this.showSpinner();
      await this.$store
        .dispatch('RESTORE_PRODUCT', product.id)
        .then(async () => {
          this.$store.commit('setSelectedIndexToProduct', -1);
          await this.FETCHS('FETCH_PRODUCT_WITH_COMPANY');
          this.$store.commit('setManagementDataToProduct', {
            id: -1,
            product_group_yn: false,
            product_group_list: [],
            product_group_type_id: null,
            name: '',
            standard: '',
            code: '',
            stock_size: 0,
            stock_unit_id: null,
            delivery_unit_id: -1,
            resource_type_id: null,
            box_unit_id: null,
            default_store_id: null,
            detail: '',
            company_list: [],
            tax: null,
            inspection: null,
          });
        })
        .catch(() => {
          this.openOneButtonModal('복구실패', '제품 복구 중 오류 발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },

    typing(e) {
      this.search_value = e.target.value;
      this.selectRow(-1);
    },
    typingDetail(e) {
      this.detail_value = e.target.value;
      this.selectRow(-1);
    },
    copyData() {
      let cloneManagementData = clone(this.managementData);
      cloneManagementData.name = cloneManagementData.name + '_복사됨';
      this.$store.commit('setNewDataToProduct', cloneManagementData);
      let arrTemp = clone(this.managementData.company_list);
      this.$store.commit('setNewProductCompany', arrTemp);
      this.$store.commit('setOpenTabIndexToProductPage', 1);
    },
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    selectRow(index) {
      this.touchflag = false;
      this.$store.commit('setSelectedIndexToProduct', index);
      if (index == -1) {
        this.$store.commit('setManagementDataToProduct', {
          id: -1,
          product_group_yn: false,
          product_group_list: [],
          product_group_type_id: null,
          name: '',
          standard: '',
          code: '',
          stock_size: 0,
          stock_unit_id: null,
          delivery_unit_id: -1,
          resource_type_id: null,
          box_unit_id: null,
          default_store_id: null,
          detail: '',
          company_list: [],
          tax: null,
          inspection: null,
        });
      } else {
        this.$store.commit(
          'setManagementDataToProduct',
          this.lodash.clonedeep(this.filtered_products[index]),
        );
      }
    },
    getUnitName(id) {
      let hitValue = this.unit_options.find(x => x.value == id);
      return hitValue == undefined ? '' : hitValue.label;
    },
    getCompanyName(id) {
      let hitValue = this.companys.find(x => x.id == id);
      return hitValue == undefined ? '' : hitValue.name;
    },
    getPrimaryCompanyName(arr) {
      if (arr.length < 1) return '';

      let hitValue = this.companys.find(x => x.id == arr[0].company_id);
      return hitValue == undefined
        ? ''
        : hitValue.name +
            (arr.length > 1 ? ` 외 ${arr.length - 1}개 업체` : '');
    },
    getResourceTypeName(id) {
      let hitValue = this.product_types.find(x => x.id == id);
      return hitValue == undefined ? '' : hitValue.name;
    },
    // toggleTax() {
    //   if (this.managementMode == true && this.selectedIndex != -1) {
    //     this.managementData.tax = !this.managementData.tax;
    //   }
    // },
    pushManagementCompany(arg) {
      console.log(arg);
      if (
        this.managementData.company_list.find(x => x.company_id == arg.id) !=
        undefined
      ) {
        this.showCompanySearch = false;
        return;
      }
      this.managementData.company_list.push({
        company_id: arg.id,
        product_id: this.managementData.id,
      });
      this.showCompanySearch = false;
    },
    deleteManagementCompany(index) {
      if (index == 0 && this.managementData.company_list.length > 1) {
        this.managementData.company_list[1].primary_comp = true;
      }
      this.managementData.company_list.splice(index, 1);
    },
    async deleteProduct() {
      this.showSpinner();
      // console.log(this.filtered_products[this.selectedIndex].id);
      console.log('들어옴');
      await this.$store
        .dispatch(
          'DELETE_PRODUCT',
          this.filtered_products[this.selectedIndex].id,
        )
        .then(async () => {
          this.$store.commit('setSelectedIndexToProduct', -1);
          await this.FETCHS('FETCH_PRODUCT_WITH_COMPANY');
          this.$store.commit('setManagementDataToProduct', {
            id: -1,
            product_group_yn: false,
            product_group_list: [],
            product_group_type_id: null,
            name: '',
            standard: '',
            code: '',
            stock_size: 0,
            stock_unit_id: null,
            delivery_unit_id: -1,
            resource_type_id: null,
            box_unit_id: null,
            default_store_id: null,
            detail: '',
            company_list: [],
            tax: null,
            inspection: null,
          });
        })
        .catch(() => {
          this.openOneButtonModal('삭제 중 오류', '자원 삭제 중 오류');
        })
        .finally(() => {
          this.CloseModal();
          this.hideSpinner();
        });
    },
    async submitForm() {
      if (this.checkValidate.length > 0) {
        this.openOneButtonModal(
          '내용 입력',
          `${this.checkValidate.join()}
          은(는) 반드시 입력하여 주십시오.`,
        );
        this.baseInfo = true;
        return;
      }

      if (this.validName) {
        this.openOneButtonModal(
          '수정 불가',
          `같은 이름의 제품이 등록이 되어있습니다.<br />다른 제품명을 등록해주세요.`,
        );
        return;
      }

      this.showSpinner();
      let payload = this.lodash.clonedeep(this.managementData);

      if (payload.product_group_yn && payload.product_group_type_id == 1) {
        payload.inspection = false;
        payload.tax = false;
      }

      if (!payload.product_group_yn && payload.product_group_type_id != null) {
        payload.product_group_type_id = null;
      }

      payload.name = payload.name.trim();
      payload.standard = payload.standard.trim();
      payload.code = payload.code.trim();
      payload.delivery_unit_id = payload.stock_unit_id;
      payload.stock_size = this.$makeNumber(payload.stock_size);

      await this.$store
        .dispatch('UPDATE_PRODUCT', payload)
        .then(async () => {
          await this.DELETE_WITH_PAYLOAD(
            'DELETE_SELLER_COMPANY_ALL',
            payload.id,
          );
          await this.DELETE_WITH_PAYLOAD(
            'DELETE_PRODUCT_GROUP_ALL',
            payload.id,
          );
          if (payload.company_list.length > 0) {
            await this.$store
              .dispatch('INSERT_SELLER_COMPANY_LIST', {
                data_list: payload.company_list,
              })
              .catch(error => {
                console.log(error);
                this.openOneButtonModal(
                  '수정 중 오류',
                  '제품 수정 중 오류 발생',
                );
                return;
              });
          }
          if (payload.product_group_list.length > 0) {
            payload.product_group_list.map(x => {
              x.quantity = this.$makeNumber(x.quantity);
              return x;
            });
            await this.$store
              .dispatch('INSERT_PRODUCT_GROUP_LIST', {
                data_list: payload.product_group_list,
              })
              .catch(error => {
                console.log(error);
                this.openOneButtonModal(
                  '수정 중 오류',
                  '제품 수정 중 오류 발생',
                );
                return;
              });
          }
          this.openOneButtonModal(
            '수정 성공',
            '제품을 성공적으로 수정하였습니다.',
          );
          this.baseInfo = false;
          this.selectRow(-1);
          this.FETCHS('FETCH_PRODUCT_WITH_COMPANY');
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal('수정 중 오류', '제품 수정 중 오류 발생');
        });
    },
    async FETCHS(apiName) {
      this.showSpinner();
      await this.$store
        .dispatch(apiName)
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '자원 관련 정보 로드 중 오류',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    checkProcess(procuctId) {
      const goingPage = routes[0].children.find(x => x.path == '/code/bom');
      if (goingPage != undefined) {
        goingPage.meta.select = this.lodash.clonedeep(procuctId);
        this.$router.push('/code/bom');
      }
    },
    newProduct(newProcuctId) {
      const goingPage = routes[0].children.find(x => x.path == '/code/bom');
      if (goingPage != undefined) {
        goingPage.meta.select = this.lodash.clonedeep(newProcuctId);
        this.$router.push('/code/bom');
      }
    },
    changeSelectric(type) {
      this.selectRow(-1);
      this.$store.commit('setSearchTypeToProductPage', type);
    },
    getProductGroupName(groupList) {
      if (groupList.length < 1) return '';

      let hitValue = this.products.find(
        x => x.id == groupList[0].member_product_id,
      );
      return hitValue == undefined
        ? ''
        : hitValue.name +
            (groupList.length > 1 ? ` 외 ${groupList.length - 1}개 제품` : '');
    },
  },
  watch: {
    managementData(newValue) {
      if (newValue.product_group_yn) {
        newValue.product_group_list.forEach(el => {
          el.id = el.member_product_id;
        });
      }
    },
  },
  async created() {
    if (this.products.length == 0) {
      await this.FETCHS('FETCH_PRODUCT_WITH_COMPANY');
    }
    if (this.companys.length == 0) {
      await this.FETCHS('FETCH_COMPANY');
    }
    if (this.product_types.length == 0) {
      await this.FETCHS('FETCH_RESOURCE_TYPE');
    }
    if (this.units.length == 0) {
      await this.FETCHS('FETCH_UNIT');
    }
    if (this.box_options.length <= 1) {
      await this.FETCHS('FETCH_BOX');
    }
    if (this.stores.length == 0) {
      await this.FETCHS('FETCH_STORE');
    }
    if (this.boms.length < 1) {
      await this.FETCH('FETCH_BOM', 'BOM');
    }
    if (
      this.managementData.id != undefined &&
      this.managementData.id != null &&
      this.managementData.id != -1
    ) {
      this.$store.commit(
        'setSelectedIndexToProduct',
        this.filtered_products.findIndex(x => x.id == this.managementData.id),
      );
    }
    this.showSelectric = true;
  },
};
</script>

<style></style>
